html, body, #root {
  height: 100%;
  min-height: 100vh;
}

.error {
  color: red;
}

.App {
  height: 100%;
}

.modal-content {
  z-index: 1060;
}

.chart {
  height: 100%;
  width: 100%;
}

tr {
  vertical-align: baseline;
}